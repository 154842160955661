import React from 'react';
import { graphql } from 'gatsby';
import 'moment/locale/es';
import 'moment/locale/pt';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PagerComponent from '../components/PagerComponent';

export const query = graphql`
 query CommissionsPage($id: String) {
    prismic {
      allCommissions_pages(id: $id) {
        edges {
          node {
            _meta {
              lang
            }
          }
        }
      } 
    }
  }
`;

const Commissions = ({ data, location }) => {
  const page = data.prismic.allCommissions_pages.edges[0];

  if (!page || !page.node) {
    return null;
  }

  const {
    _meta: { lang },
  } = page.node;

  return (
    <Layout lang={lang} location={location}>
      <SEO title="Home" />
      <PagerComponent initialSection="commissions" lang={lang} />
    </Layout>
  );
};

export default Commissions;
